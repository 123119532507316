import firebase from 'firebase'

const COLLECTION_NAME = 'lyrics'

const actions = {
  getLyric ({ commit }, songId) {
    return firebase.firestore()
      .collection('lyrics')
      .where('song_id', '==', songId)
      .get()
  },

  addLyric ({ commit }, payload) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .add(payload)
  },

  editLyric ({ commit }, { id, payload }) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .doc(id)
      .update(payload)
  },

  deleteLyric ({ commit }, { id }) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .doc(id)
      .delete()
  }
}

export default {
  actions
}
