import firebase from 'firebase'

const COLLECTION_NAME = 'songs'

const actions = {
  addSong ({ commit }, payload) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .add(payload)
  },

  addMultipleSongs ({ commit }, payload) {
    const db = firebase.firestore()
    const batch = db.batch()
    payload.forEach((doc) => {
      var docRef = db.collection(COLLECTION_NAME).doc()
      batch.set(docRef, doc)
    })
    batch.commit()
  },

  editSong ({ commit }, { id, payload }) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .doc(id)
      .update(payload)
  },

  deleteSong ({ commit }, { id }) {
    return firebase.firestore()
      .collection(COLLECTION_NAME)
      .doc(id)
      .delete()
  }
}

export default {
  actions
}
