
// Mutation names
const SET_SNACKBAR = 'SET_SNACKBAR'
const SET_LOAD_SPINNER = 'SET_LOAD_SPINNER'

const state = {
  snackbar: {},
  loadSpinner: false
}

const getters = {
  snackbar (state) {
    return state.snackbar
  },
  loadSpinner (state) {
    return state.loadSpinner
  }
}

const actions = {
  showToast ({ commit }, payload) {
    if (String(payload.show) !== 'false') {
      payload.show = true
    }
    if (!payload.timeout) {
      payload.timeout = 5000
    }
    if (!payload.color) {
      payload.color = 'info'
    }
    commit(SET_SNACKBAR, payload)
  },
  showSpinner ({ commit }) {
    commit(SET_LOAD_SPINNER, true)
  },
  hideSpinner ({ commit }) {
    commit(SET_LOAD_SPINNER, false)
  }
}

const mutations = {
  [SET_SNACKBAR] (state, payload) {
    state.snackbar = payload
  },
  [SET_LOAD_SPINNER] (state, payload) {
    state.loadSpinner = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
