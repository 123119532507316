<template>
  <v-app>
    <v-main class="primary darken-4">
      <!-- <v-alert color="error" dark icon="mdi-alert" border="right" class="ma-0">
        <strong>This Website is currently under construction !</strong>
      </v-alert> -->
      <public-layout></public-layout>
    </v-main>

    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :color="snackbar.color">
      <div class="d-flex justify-space-between">
        <div class="ma-auto ml-0">
          {{ snackbar.text }}
        </div>
        <v-btn text @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>

    <v-overlay :value="loadSpinner" z-index="300">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Check for UI update -->
    <!-- <v-snackbar v-model="updateExists" centered :timeout="-1">
      An update is available
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          color="info"
          class="my-4 mx-2"
          @click="refreshApp"
        >
          Update
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import update from './mixins/update'

export default {
  name: 'App',
  mixins: [update],
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['snackbar', 'loadSpinner'])
  },
  components: {
    PublicLayout: () => import('@/layouts/PublicSite')
  }
}
</script>

<style lang="scss">
@import "@/styles/variables.scss";

/* thead {
  background-color: var(--primary-color);
} */
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
