export default [
  {
    path: '',
    component: resolve => require(['@/views/PublicSite/Home/Index.vue'], resolve),
    meta: {
      title: 'Meghpakhi Academy of Music'
    }
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => require(['@/views/PublicSite/About.vue'], resolve),
    meta: {
      title: 'About'
    }
  },
  {
    path: '/discography',
    name: 'discography',
    component: resolve => require(['@/views/PublicSite/Discography/Index.vue'], resolve),
    meta: {
      title: 'Discography'
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: resolve => require(['@/views/PublicSite/Gallery/Index.vue'], resolve),
    meta: {
      title: 'Gallery'
    }
  },
  {
    path: '/media',
    name: 'media',
    component: resolve => require(['@/views/PublicSite/Media.vue'], resolve),
    meta: {
      title: 'Media'
    }
  },
  {
    path: '/literature',
    name: 'literature',
    component: resolve => require(['@/views/PublicSite/Literature.vue'], resolve),
    meta: {
      title: 'Literature'
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: resolve => require(['@/views/PublicSite/Contact.vue'], resolve),
    meta: {
      title: 'Contact Us'
    }
  },
  {
    path: '/academy',
    name: 'academy',
    component: resolve => require(['@/views/PublicSite/Academy.vue'], resolve),
    meta: {
      title: 'Meghpakhi Academy of Music'
    }
  },
  {
    path: '*',
    component: resolve => require(['@/views/misc/PageNotFound'], resolve)
  }
]
