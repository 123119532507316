import firebase from 'firebase'
import router from '@/router'
import { UserRoles } from '@/utils/constants'

const AUTH_USER = 'AUTH_USER'
const CURRENT_USER = 'CURRENT_USER'
const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA'

const state = {
  authUser: null,
  curUser: null
}

const getters = {
  user () {
    return firebase.auth().currentUser
  },
  isUserAuthenticated () {
    // return true
    return state.authUser !== null && state.authUser !== undefined
  },
  isUserEmailVerified () {
    return getters.isUserAuthenticated() && firebase.auth().currentUser.emailVerified
  },
  currentUser () {
    return state.curUser
  },
  isUserAdmin () {
    return state.curUser &&
      [UserRoles.ADMIN, UserRoles.SUPER_ADMIN].indexOf(state.curUser.role) >= 0
  },
  isUserSuperAdmin () {
    return state.curUser && state.curUser.role === UserRoles.SUPER_ADMIN
  }
}

const actions = {
  async signin ({ commit }, payload) {
    let user = null
    if (payload.type === 'emailPassword') {
      const res = await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      user = res.user
    } else {
      const res = await firebase.auth().signInWithPopup(payload.provider)
      user = res.user
      if (res.additionalUserInfo.isNewUser) {
        // Add the new user to database
        const curUser = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          mobile: user.phoneNumber,
          address: '',
          blood: '',
          role: UserRoles.MEMBER
        }
        await firebase.database().ref('users').push(curUser)
      }
    }
    const newUser = {
      uid: user.uid
    }
    commit(AUTH_USER, newUser)
    firebase.database().ref('users').orderByChild('email').equalTo(user.email).on('value', snapshot => {
      if (!snapshot) {
        throw new Error('User not found')
      }
      const [userId, userDetail] = Object.entries(snapshot.val())[0]
      commit(CURRENT_USER, {
        ...userDetail,
        id: userId
      })
    })
    router.replace('/')
  },

  async signout ({ commit }) {
    await firebase.auth().signOut()
    commit(CLEAR_AUTH_DATA)
    router.replace('/')
    router.go()
  },

  setAuthenticatedUser ({ commit }, user) {
    if (user) {
      commit(AUTH_USER, user.uid)
      firebase.database().ref('users').orderByChild('uid').equalTo(user.uid).on('value', snapshot => {
        if (!snapshot) {
          throw new Error('User not found')
        }
        const [userId, userDetail] = Object.entries(snapshot.val())[0]
        commit(CURRENT_USER, {
          ...userDetail,
          id: userId
        })
      })
    } else {
      commit(CLEAR_AUTH_DATA)
    }
  },

  sendEmailVerification () {
    return firebase.auth().currentUser.sendEmailVerification()
  },

  sendResetPasswordEmail ({ commit }, email) {
    return firebase.auth().sendPasswordResetEmail(email)
  },

  async editProfile ({ commit }, { userId, payload }) {
    return firebase.database().ref(`/users/${userId}`).update(payload)
  },

  async deleteAccount ({ commit }, userId) {
    await firebase.auth().currentUser.delete()
    await firebase.database().ref(`/users/${userId}`).remove()
    router.replace('/')
    router.go()
  },

  async signup ({ commit }, payload) {
    const user = await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
    commit(AUTH_USER, user.user.uid)

    // Add the new user to the database
    const curUser = {
      uid: user.user.uid,
      displayName: payload.displayName,
      email: payload.email,
      mobile: payload.mobile,
      address: payload.address,
      role: UserRoles.MEMBER
    }
    if (payload.blood) curUser.blood = payload.blood

    // Set Current User
    const data = await firebase.database().ref('users').push(curUser)
    commit(CURRENT_USER, {
      ...data,
      id: data.key
    })

    // Send email verification
    firebase.auth().currentUser.sendEmailVerification()
    // Update profile in the auth data
    user.user.updateProfile({
      displayName: payload.displayName
    })
  }
}

const mutations = {
  [AUTH_USER] (state, username) {
    state.authUser = username
  },
  [CURRENT_USER] (state, user) {
    state.curUser = user
  },
  [CLEAR_AUTH_DATA] (state) {
    state.authUser = null
    state.curUser = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
