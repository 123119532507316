import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import songs from './modules/songs'
import lyrics from './modules/lyrics'
import utils from './modules/utils'
import preferences from './modules/preferences'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    songs,
    lyrics,
    utils,
    preferences
  }
})
