import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,{staticClass:"primary darken-4"},[_c('public-layout')],1),_c(VSnackbar,{attrs:{"timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"ma-auto ml-0"},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)]),_c(VOverlay,{attrs:{"value":_vm.loadSpinner,"z-index":"300"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }