import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from 'firebase'
import VueViewer from 'v-viewer'

import '@/mixins/global'
import '@/styles/global.scss'
import '@/components/globalComponents'
import 'viewerjs/dist/viewer.css'

Vue.use(VueViewer)
Vue.config.productionTip = false

// Firebase initialization
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
})

let isAppAlreadyInitialized = false
// Check if user logged in and set the store data
firebase.auth().onAuthStateChanged(user => {
  store.dispatch('setAuthenticatedUser', user)

  if (!isAppAlreadyInitialized) {
    // Create the main app after fetching user data
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      created () {
        isAppAlreadyInitialized = true
      }
    }).$mount('#app')
  }
})

firebase.analytics()
