import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'
import store from '../store'

Vue.use(Vuetify)
Vue.prototype.isDark = true

export default new Vuetify({
  theme: {
    dark: store.getters.isDarkMode,
    themes: {
      light: {
        primary: '#00ADEF',
        secondary: '#00A650',
        accent: '#FEF200',
        duicent: '#F31926',
        tincent: '#BB88DD',
        charcent: '#ECECEC',
        pachcent: '#FFBCA0',
        chhoycent: '#EBBB33'
        /* error: '#FF9095',
        warning: '#FFC390',
        info: '#909AF4',
        success: '#CCF490', */
      },
      dark: {
        primary: '#04bea7',
        secondary: '#0477BE',
        accent: '#888888',
        duicent: '#05f0d4',
        tincent: '#a804be'
      }
    }
  },
  /* breakpoint: {
    thresholds: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1224
    }
  }, */
  icons: {
    iconfont: 'mdi'
  }
})
