import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    setTimeout(() => {
      const elem = document.getElementById('main-sheet')
      elem.scrollTop = 0
      elem.scrollLeft = 0
    }, 100)
  }
})

router.beforeEach((to, from, next) => {
  // Hide loader anyways
  store.dispatch('showSpinner')

  // Set tab title
  document.title = 'Goutam Ghosal' + (to.meta.title ? ` | ${to.meta.title}` : '')
  next()

  // Check from requiredAuth Guard
  if (to.matched.some(record => record.meta.requiredAuth)) {
    // Check if NOT logged in
    if (!store.getters.isUserAuthenticated) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  }

  // Check from notLoggedIn Guard
  if (to.matched.some(record => record.meta.notLoggedIn)) {
    // Check if logged in
    if (store.getters.isUserAuthenticated) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  setTimeout(() => {
    store.dispatch('hideSpinner')
  }, 500)
})

export default router
