// Mutation names
const DARK_MODE = 'DARK_MODE'

const state = {
  darkMode: (localStorage.getItem('darkMode') || 'enabled') === 'enabled'
}

const getters = {
  isDarkMode (state) {
    return state.darkMode
  }
}

const actions = {
  setDarkMode ({ commit }, count) {
    commit(DARK_MODE, count)
  }
}

const mutations = {
  [DARK_MODE] (state, isEnabled) {
    state.darkMode = isEnabled
    localStorage.setItem('darkMode', isEnabled ? 'enabled' : 'disabled')
  }
}

export default {
  //  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
